import { render, staticRenderFns } from "./BubbleNumber.vue?vue&type=template&id=5414e4fc&scoped=true&"
import script from "./BubbleNumber.vue?vue&type=script&lang=js&"
export * from "./BubbleNumber.vue?vue&type=script&lang=js&"
import style0 from "./BubbleNumber.vue?vue&type=style&index=0&id=5414e4fc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5414e4fc",
  null
  
)

export default component.exports