<template>
  <span class="number-bubble">{{ number }}</span>
</template>

<script>
export default {
  props: ["number"],
};
</script>

<style lang="scss" scoped>
.number-bubble {
  display: inline-flex;
  top: $mpadding/4;
  right: $mpadding/4;
  font-size: 80%;
  align-items: center;
  justify-content: center;
  border-radius: 2pc;
  &:not(.inline) {
    position: absolute;
  }
}
</style>
